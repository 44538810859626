import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import kirschbluetencarreFlyer from "../../../../src/images/produkte/flyer/flyer-wsg.jpg";
import megaBeutelFlyer from "../../../../src/images/produkte/flyer/flyer-meg.jpg";
import vivawestFlyer from "../../../../src/images/produkte/flyer/flyer-vivawest.jpg";
import buderusPeru from "../../../../src/images/produkte/flyer/flyer-buderus.jpg";
import studienkreisFlyer from "../../../../src/images/produkte/flyer/studienkreis-flyer.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  kirschbluetencarreFlyer,
  megaBeutelFlyer,
  vivawestFlyer,
  buderusPeru,
  studienkreisFlyer,
  React
};