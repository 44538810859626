import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import wsgBaustellenschild from "../../../../src/images/produkte/baustellenschilder/wsg.jpg";
import vivawestBaustellenschild from "../../../../src/images/produkte/baustellenschilder/vivawest.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  wsgBaustellenschild,
  vivawestBaustellenschild,
  React
};