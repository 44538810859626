import React, { useEffect, useState } from "react"
import * as styles from "./ytvideo.module.css"
import YouTube from "react-youtube"
import "../layout/layout.css"

const Ytvideo = props => {
  //wrapper fuer responsive darstellung

  const [enableYoutube, setEnableYoutube] = useState(true)

  useEffect(() => {
    setEnableYoutube(localStorage.getItem("enable-youtube"))
  }, [])

  if (!enableYoutube) {
    return (
      <div className="bg-light"><p className="p-4">[Medium wird nicht angezeigt, weil die Zustimmung zur Nutzung von benötigten Cookies fehlt.]</p></div>
    )
  }

  return (
    <div class="embed-responsive embed-responsive-16by9">
      <YouTube
        videoId={props.videoId}
        opts={{
          playerVars: {
            autoplay: 0,
            controls: 1,
            rel: 0,
            showinfo: 0,
            playsinline: 1,
            mute: 0,
          },
        }}
        className="video-iframe embed-responsive-item"
      />
    </div>
  )
}

// Specifies the default values for props:
Ytvideo.defaultProps = {
  videoId: "",
}

export default Ytvideo
