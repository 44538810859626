import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import mwbMaklerAnzeige from "../../../../src/images/produkte/anzeige/anzeige-mwb-makler.jpg";
import megAnzeige from "../../../../src/images/produkte/anzeige/anzeige-meg.jpg";
import wsgAnzeige from "../../../../src/images/produkte/anzeige/anzeige-vivawest.jpg";
import vivawestAnzeige from "../../../../src/images/produkte/anzeige/anzeige-vivawest.jpg";
import seckelmannAnzeige from "../../../../src/images/produkte/anzeige/anzeige-seckelmann.jpg";
import stkAnzeige from "../../../../src/images/produkte/anzeige/anzeige-stk.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  mwbMaklerAnzeige,
  megAnzeige,
  wsgAnzeige,
  vivawestAnzeige,
  seckelmannAnzeige,
  stkAnzeige,
  React
};