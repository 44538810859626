import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import aedRollUp from "../../../../src/images/produkte/roll-up/aed.jpg";
import wsgRollUp from "../../../../src/images/produkte/roll-up/wsg-1.jpg";
import mwbmaklerRollUp from "../../../../src/images/produkte/roll-up/mwbmakler.jpg";
import vivawestRollUp from "../../../../src/images/produkte/roll-up/vivawest.jpg";
import buderusRollUp from "../../../../src/images/produkte/roll-up/buderus.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  aedRollUp,
  wsgRollUp,
  mwbmaklerRollUp,
  vivawestRollUp,
  buderusRollUp,
  placeholder,
  React
};