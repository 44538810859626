import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import mwbMarke from "../../../../src/images/produkte/marke/cd-mwb.jpg";
import ruhrbania from "../../../../src/images/produkte/marke/ruhrbania.jpg";
import studienkreisMarke from "../../../../src/images/produkte/marke/cd-stk.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  mwbMarke,
  ruhrbania,
  studienkreisMarke,
  React
};