import React, { createContext, useReducer } from "react"

const initialState = {
  articles: [],
}
const StateContext = createContext()
const DispatchContext = createContext()

const ADD_ARTICLE = "qmarkting/unit-mittelstand/article/add"
const REMOVE_ARTICLE = "qmarkting/unit-mittelstand/article/remove"

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    let index
    switch (action.type) {
      case ADD_ARTICLE:
        let articles = JSON.parse(JSON.stringify(state.articles))
        index = articles.findIndex(el => el.type === action.articleType)
        if (index === -1) {
          articles.push({
            type: action.articleType,
          })
        }
        return {
          ...state,
          articles,
        }
      case REMOVE_ARTICLE:
        return {
          ...state,
          articles: state.articles.filter(el => el.type !== action.articleType),
        }
      default:
        return state
    }
  }, initialState)

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export { ADD_ARTICLE, REMOVE_ARTICLE, DispatchContext, StateContext }
export default StateProvider
