import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import shootingstk from "../../../../src/images/produkte/fotoshooting/shooting-stk.jpg";
import shootingseckelmann from "../../../../src/images/produkte/fotoshooting/shooting-seckelmann.jpg";
import shootingwsg from "../../../../src/images/produkte/fotoshooting/shooting-wsg.jpg";
import shootingmedl from "../../../../src/images/produkte/fotoshooting/shooting-medl.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  shootingstk,
  shootingseckelmann,
  shootingwsg,
  shootingmedl,
  placeholder,
  React
};