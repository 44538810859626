import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import mwbFahrzeugbeklebung from "../../../../src/images/produkte/fahrzeugbeklebung/fahrzeug-mwb.jpg";
import vivawestFahrzeugbeklebung from "../../../../src/images/produkte/fahrzeugbeklebung/fahrzeug-vivawest.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  mwbFahrzeugbeklebung,
  vivawestFahrzeugbeklebung,
  React
};