import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import mwbKundenmagazin from "../../../../src/images/produkte/kundenmagazin/genossen-wohnen.jpg";
import vbwKundenmagazin from "../../../../src/images/produkte/kundenmagazin/daheim.jpg";
import starclubJournal from "../../../../src/images/produkte/kundenmagazin/starclub-journal.jpg";
import sparkasseKundenmagazin from "../../../../src/images/produkte/kundenmagazin/sparkasse.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  mwbKundenmagazin,
  vbwKundenmagazin,
  starclubJournal,
  sparkasseKundenmagazin,
  placeholder,
  React
};