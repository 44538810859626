import Accordion from "react-bootstrap/Accordion";
import aragapp from "../../../../src/images/produkte/apps/arag-beratungsapp-01-um.jpg";
import bjeplaybook from "../../../../src/images/produkte/apps/bje-playbook-01-um.jpg";
import bjebaustellenplaner from "../../../../src/images/produkte/apps/bje-baustellenplaner-01-um.jpg";
import YtVideo from "../../../../src/components/ytvideo";
import * as React from 'react';
export default {
  Accordion,
  aragapp,
  bjeplaybook,
  bjebaustellenplaner,
  YtVideo,
  React
};