/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./node_modules/bootstrap/dist/css/bootstrap.css"
import React from "react"
import StateProvider from "./src/components/unit-mittelstand/provider"

// Wraps every page in a component
export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
)

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0)
  }
}
