import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import YouTube from "react-youtube";
import YtVideo from "../../../../src/components/ytvideo";
import * as React from 'react';
export default {
  Accordion,
  styles,
  placeholder,
  YouTube,
  YtVideo,
  React
};