import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import mwbKampagne from "../../../../src/images/produkte/kampagne/kampagne-mwb.jpg";
import sparkasseKampagne from "../../../../src/images/produkte/kampagne/kampagne-sparkasse.jpg";
import vivawestKampagne from "../../../../src/images/produkte/kampagne/kampagne-vivawest.jpg";
import studienkreisKampagne from "../../../../src/images/produkte/kampagne/kampagne-studienkreis.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  mwbKampagne,
  sparkasseKampagne,
  vivawestKampagne,
  studienkreisKampagne,
  placeholder,
  React
};