import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import wsgGeschaeftsbericht2018 from "../../../../src/images/produkte/geschaeftsbericht/wsg-gb-2018.jpg";
import vbwGeschaeftsbericht from "../../../../src/images/projekte/vbw-gb-website/landing/vbw-gb-og.jpg";
import wsgGeschaeftsbericht from "../../../../src/images/projekte/wsg-gb-website/thumbleiste/wsg-gb-02.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  placeholder,
  wsgGeschaeftsbericht2018,
  vbwGeschaeftsbericht,
  wsgGeschaeftsbericht,
  React
};