import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import buderusMailing from "../../../../src/images/produkte/mailing/buderus-1.jpg";
import oryxMailing from "../../../../src/images/produkte/mailing/oryx-nl.jpg";
import congstarMailing from "../../../../src/images/produkte/mailing/congstar-nl.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  buderusMailing,
  oryxMailing,
  congstarMailing,
  React
};