import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import kuhmichelBroschuere from "../../../../src/images/produkte/broschuere/broschuere-kuhmichel.jpg";
import mh025Broschuere from "../../../../src/images/produkte/broschuere/mh-0-25.jpg";
import vivawestBroschuere from "../../../../src/images/produkte/broschuere/broschuere-vivawest.jpg";
import stkBroschuere from "../../../../src/images/produkte/broschuere/broschuere-studienkreis.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  kuhmichelBroschuere,
  mh025Broschuere,
  vivawestBroschuere,
  stkBroschuere,
  React
};