import Accordion from "react-bootstrap/Accordion";
import * as styles from "../../../../src/data/qmarketing-fuer-den-mittelstand/elements.module.css";
import studienkreis from "../../../../src/images/produkte/plakat/studienkreis.jpg";
import sparkasse from "../../../../src/images/produkte/plakat/sparkasse.jpg";
import megabeutel from "../../../../src/images/produkte/plakat/megabeutel.jpg";
import medl from "../../../../src/images/produkte/plakat/medl.jpg";
import placeholder from "../../../../src/images/produkte/_titelbilder/placeholder.jpg";
import * as React from 'react';
export default {
  Accordion,
  styles,
  studienkreis,
  sparkasse,
  megabeutel,
  medl,
  placeholder,
  React
};